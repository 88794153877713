<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.date }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-checkbox v-model="form.downPayment" label="Down Payment" disabled></v-checkbox>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-checkbox v-model="form.cheque" label="Cheque/Giro#" disabled></v-checkbox>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" v-if="form.isCheque">
                <span>{{ form.chequeNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PO Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.poNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>WO Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.woNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ form.voucher }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div v-if="form.poNumber === 'Import'" class="mb-3">
          <v-data-table
            :headers="headerTax"
            :items="form.taxs"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.no="{ index }">
              {{ index + 1 }}
            </template>
          </v-data-table>
        </div>
        <div v-if="form.poNumber === 'Other Document'">
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row class="py-0">
                <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                  <v-subheader>Tax Date<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="6" lg="6">
                  <span>{{ form.otherDocument.taxDate }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row class="py-0">
                <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                  <v-subheader>Tax Number<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="6" lg="6">
                  <span>{{ form.otherDocument.taxNumber }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row class="py-0">
                <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                  <v-subheader>Vendor<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="6" lg="6">
                  <span>{{ form.otherDocument.vendorName }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-row class="py-0">
                <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                  <v-subheader>Invoice Number<span class="red--text">*</span></v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="6" lg="6">
                  <span>{{ form.otherDocument.invoiceNumber }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Account <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.glTransactionCOAList"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.glTransactionCOAList.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.totalAmount="{ item }">
                          {{ formatPrice(item.totalAmount) }}
                        </template>
                        <template v-slot:item.rate="{ item }">
                          {{ formatPrice(item.rate) }}
                        </template>
                        <template v-slot:item.totalAmountIDR="{ item }">
                          {{ formatPrice(item.totalAmountIDR) }}
                        </template>
                        <template v-slot:item.pib="{ item }">
                          <v-checkbox v-model="item.pib" disabled></v-checkbox>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
export default {
  name: "detail-gl-transaction",
  props: {
    form: Object,
    id: Number,
  },
  data: () => ({
    headerTax: [
      {
        id: 1,
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 1,
        text: "Tax Date",
        align: "center",
        sortable: false,
        value: "taxDate",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 1,
        text: "Tax Number",
        align: "center",
        sortable: false,
        value: "taxNumber",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 1,
        text: "PIB Date",
        align: "center",
        sortable: false,
        value: "pibDate",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 1,
        text: "Stan",
        align: "center",
        sortable: false,
        value: "stan",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 1,
        text: "AWB",
        align: "center",
        sortable: false,
        value: "awb",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 1,
        text: "NTPN",
        align: "center",
        sortable: false,
        value: "ntpn",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 1,
        text: "Invoice",
        align: "center",
        sortable: false,
        value: "invoice",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account #",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "D/C",
        value: "nature",
        sortable: false,
        divider: true,
      },
      {
        text: "Reff",
        value: "reference",
        sortable: false,
        divider: true,
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Original Amount",
        value: "totalAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount in RP",
        value: "totalAmountIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Project",
        value: "project",
        sortable: false,
        divider: true,
      },
      {
        text: "Dept",
        value: "department",
        sortable: false,
        divider: true,
      },
      {
        text: "PIB",
        value: "pib",
        sortable: false,
        divider: true,
      },
      {
        text: "NTPN",
        value: "ntpn",
        sortable: false,
        divider: true,
      },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>

<template>
  <form>
    <v-row>
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="glTransaction"
          :accounts="accounts"
          :tempAccounts="tempAccounts"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail :id="id" :form="glTransaction" v-if="edit"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="printJournal">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Journal</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="glTransaction.formNumber"
      title="GL Transaction"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
  </form>
</template>

<script>
import Detail from "./DetailGLTransaction";
import FormEdit from "./FormGLTransaction";
import DialogDelete from "@/components/DialogDelete";

export default {
  name: "gl-transaction-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    accounts: [],
    tempAccounts: [],
    glTransaction: {},
  }),
  methods: {
    async getGLTransaction() {
      await this.$store
        .dispatch("glTransaction/getById", this.id)
        .then(response => (this.glTransaction = response.data));
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getListAccountGL");
      if (response.status === 200) {
        this.accounts = response.data;
        this.tempAccounts = response.data.map(x => ({ ...x }));
      }
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    async deleteItem(id) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("glTransaction/delete", id)
        .then(() => {
          this.dialog = false;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.getGLTransaction();
      this.edit = true;
    },
    printJournal() {
      this.$router.push({ name: "Print Preview Journal GL Transaction", params: { id: this.id } });
    },
    getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getAccounts(), this.getGLTransaction()])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>

<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date"
                    :allowed-dates="getAllowedDates()"
                    @input="menuDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-checkbox v-model="form.downPayment" label="Down Payment"></v-checkbox>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-checkbox v-model="form.cheque" label="Cheque/Giro#"></v-checkbox>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0" v-if="form.isCheque">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  :items="listCheque"
                  v-model="form.chequeNumber"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PO Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.poNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>WO Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.woNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.voucher"
                  :items="listVoucher"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        outlined
                        flat
                        dense
                        background-color="white"
                        append-icon="mdi-magnify"
                        :items="accounts"
                        item-text="accountName"
                        return-object
                        v-model="account"
                        :filter="filterObject"
                      >
                        <template v-slot:item="data">
                          <v-list-item v-on="data.on" @click.stop="addAccount(data.item)">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                              <v-list-item-subtitle
                                v-html="data.item.accountNumber"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Account <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.glTransactionCOAList"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.glTransactionCOAList.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.totalAmount="{ item }">
                          {{ formatPrice(item.totalAmount) }}
                        </template>
                        <template v-slot:item.rate="{ item }">
                          {{ formatPrice(item.rate) }}
                        </template>
                        <template v-slot:item.totalAmountIDR="{ item }">
                          {{ formatPrice(item.totalAmountIDR) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-account-gl
      :item="item"
      :index="index"
      :dialog="dialog"
      @save="addItem"
      @close="close"
      @deleteItem="deleteItem"
      @autoComplete="autoComplete"
    ></dialog-account-gl>
  </v-form>
</template>

<script>
import DialogAccountGL from "@/components/DialogAccountGL";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "form-gl-transaction",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-account-gl": DialogAccountGL,
  },
  props: {
    form: Object,
    tempAccounts: Array,
  },
  computed: {
    setArray() {
      return this.tempAccounts;
    },
  },
  watch: {
    setArray(val) {
      this.accounts = val;
    },
  },
  data: () => ({
    valid: true,
    menuDate: false,
    dialog: false,
    accounts: [],
    item: {},
    index: -1,
    account: null,
    listVoucher: ["Petty Cash", "Journal", "Received", "Payment"],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account #",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "D/C",
        value: "nature",
        sortable: false,
        divider: true,
      },
      {
        text: "Reff",
        value: "reference",
        sortable: false,
        divider: true,
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Original Amount",
        value: "totalAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount in RP",
        value: "totalAmountIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Project",
        value: "project",
        sortable: false,
        divider: true,
      },
      {
        text: "Dept",
        value: "department",
        sortable: false,
        divider: true,
      },
    ],
    reff: "",
    remark: "",
  }),

  methods: {
    async update() {
      this.$store.commit("SET_LOADER", true);
      if (this.$refs.form.validate()) {
        await this.$store
          .dispatch("glTransaction/update", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    deleteItem(index) {
      if (index > -1) {
        this.form.glTransactionCOAList.splice(index, 1);
        this.account = null;
        this.accounts = this.tempAccounts.map(x => ({ ...x }));
        this.dialog = false;
      }
    },
    close() {
      this.account = null;
      this.accounts = this.tempAccounts.map(x => ({ ...x }));
      this.dialog = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.form.glTransactionCOAList.findIndex(x => x === item);
      this.dialog = true;
    },
    addAccount(item) {
      item.reference = this.reff;
      item.remark = this.remark;
      this.index = this.form.glTransactionCOAList.findIndex(x => x === item);
      this.item = item;
      this.dialog = true;
    },
    addItem(item) {
      if (this.index > -1) {
        this.form.glTransactionCOAList[this.index] = item;
      } else {
        this.form.glTransactionCOAList.push({ ...item });
      }
      this.account = null;
      this.accounts = this.tempAccounts.map(x => ({ ...x }));
    },
    autoComplete(item) {
      this.reff = item.reff;
      this.remark = item.remark;
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
};
</script>

<style></style>
